import { render, staticRenderFns } from "./addCourseShop.vue?vue&type=template&id=17a56400&scoped=true&"
import script from "./addCourseShop.vue?vue&type=script&lang=js&"
export * from "./addCourseShop.vue?vue&type=script&lang=js&"
import style0 from "./addCourseShop.vue?vue&type=style&index=0&id=17a56400&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17a56400",
  null
  
)

export default component.exports