<template>
  <div class="training-add-student">
    <table-list
      :title="`当前人数${dataList.length}名`"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="dataList"
      :columns="columns(this)"
      :btns="btns(this)"
      :options="{ selection: true }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
    ></table-list>
    <AddRegRecord ref="addRegRecord" :class-cert-id="classCertId" @success="addSuccess" />
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '从报名记录中选择',
    method: _this.handleAdd,
    width: 140,
  },
  {
    label: '删除',
    type: 'danger',
    method: _this.handleDelete,
  },
]
// 搜索框配置
const searchForm = () => [
  {
    label: '姓名',
    prop: 'name',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'signUserName',
    label: '报名人姓名',
  },
  {
    prop: 'phone',
    label: '手机账号',
  },
  {
    prop: 'productName',
    label: '报名商品入口',
  },
  {
    prop: 'skuValue',
    label: '所选服务',
  },
]
import TableList from '@/components/TableList'
import { classCertStudent, classCertStudentDelete } from '@/api/certificate'
import AddRegRecord from './addRegRecord'
import to from 'await-to'
export default {
  name: 'TrainingAddStudent',
  components: {
    TableList,
    AddRegRecord,
  },
  props: {
    classCertId: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      loading: false,
      dataList: [],
      selectList: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(
        classCertStudent({ ...this.queryParams, classCertId: this.classCertId }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.dataList = res.data.list
    },
    // 查询按钮
    onSearch(queryParams) {
      this.queryParams = queryParams
      this.getList()
    },
    async handleAdd() {
      this.$refs.addRegRecord.show()
    },
    addSuccess() {
      this.getList()
    },
    handleDelete() {
      if (!this.selectList.length) return this.$message.warning('请先选择要删除的员工')
      let ids = []
      this.selectList.map(item => ids.push({ id: item.id }))
      this.$confirm('确认删除员工吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(classCertStudentDelete(ids))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getList()
      })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectList = val
    },
  },
}
</script>
