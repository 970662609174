<template>
  <div class="training-add-course">
    <table-list
      :loading="loading"
      :data="dataList"
      :columns="columns(this)"
      :btns="btns(this)"
      :options="{ selection: true }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
    ></table-list>
    <AddQuestionBank ref="addCourseShop" :class-cert-id="classCertId" @success="addSuccess" />
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '从题库中选择',
    method: _this.handleAdd,
    width: 120,
  },
  {
    label: '删除',
    type: 'danger',
    method: _this.handleDelete,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'questionStoreName',
    label: '题库名称',
  },
  {
    prop: 'questionTypeMuster',
    label: '试题包含类型',
  },
  {
    prop: 'questionNum',
    label: '题目数量',
  },
]
import TableList from '@/components/TableList'
import { classCertCourseStore, classCertCourseStoreDelete } from '@/api/certificate'
import AddQuestionBank from './addQuestionBank'
import to from 'await-to'
export default {
  name: 'TrainingAddCourse',
  components: {
    TableList,
    AddQuestionBank,
  },
  props: {
    classCertId: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      btns,
      columns,
      loading: false,
      dataList: [],
      selectList: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(
        classCertCourseStore({ ...this.queryParams, classCertId: this.classCertId }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.dataList = res.data
    },
    // 查询按钮
    onSearch(queryParams) {
      this.queryParams = queryParams
      this.getList()
    },
    handleAdd() {
      this.$refs.addCourseShop.show()
    },
    addSuccess() {
      this.getList()
    },
    handleDelete() {
      if (!this.selectList.length) return this.$message.warning('请先选择要删除的题库')
      let ids = []
      this.selectList.map(item => ids.push(item.id))
      this.$confirm('确认删除题库吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(classCertCourseStoreDelete({ questionStoreIdList: ids }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getList()
      })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectList = val
    },
  },
}
</script>
