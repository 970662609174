<!--
 * @Description: 选择教务
 * @Author: xiawenlong
 * @Date: 2021-04-22 10:06:14
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-01-17 14:45:24
-->
<template>
  <el-dialog :close-on-click-modal="false" class="add-principal" :visible.sync="dialogVisible">
    <div class="title">学堂人员列表</div>
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>
    <div class="content">
      <table-list
        ref="principalTable"
        :loading="loading"
        :data="principalList"
        :search-form="searchForm(this)"
        :columns="columns(this)"
        :options="{ selection: true, reserveSelection: true, rowKey: 'userId', height: 400 }"
        @search="onSearch"
        @selectionChange="handleSelectionChange"
      ></table-list>
      <div class="btns">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
const searchForm = () => [
  {
    label: '姓名进行搜索',
    prop: 'name',
    width: 150,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '员工姓名',
    align: 'center',
    width: 400,
  },
  {
    prop: 'mobile',
    label: '手机号',
  },
]
import TableList from '@/components/TableList'
import { classCertStaff } from '@/api/certificate'
import to from 'await-to'
export default {
  name: 'AddPrincipalDialog',
  components: { TableList },
  data() {
    return {
      searchForm,
      columns,
      dialogVisible: false,
      loading: false,
      principalList: [],
      selectPrincipal: [],
      queryParams: {},
      classCertId: '',
    }
  },
  methods: {
    show(select = [], classCertId) {
      this.dialogVisible = true
      this.classCertId = classCertId
      this.getPrincipalList()
      this.$nextTick(() => {
        this.$refs.principalTable.$refs.tableList.clearSelection()
        if (select.length) {
          select.forEach(row => {
            this.$refs.principalTable.$refs.tableList.toggleRowSelection(row)
          })
        }
      })
    },
    async getPrincipalList() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(
        classCertStaff({ ...this.queryParams, classCertId: this.classCertId }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.principalList = res.data
    },
    submit() {
      if (this.selectPrincipal.length > 2) {
        return this.$message.warning('最多选择2名教务')
      }
      this.$emit('handlePrincipalChange', this.selectPrincipal)
      this.dialogVisible = false
    },
    // 查询按钮
    onSearch(queryParams) {
      this.queryParams = queryParams
      this.getPrincipalList()
    },
    // 多选
    handleSelectionChange(val) {
      this.selectPrincipal = val
    },
  },
}
</script>
<style lang="scss" scoped>
.add-principal {
  ::v-deep.el-dialog {
    position: relative;
    width: 600px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 14px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .title {
      height: 62px;
      line-height: 62px;
      border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .content {
      padding: 30px 10px;
      > .btns {
        text-align: right;
        padding-top: 30px;
      }
    }
  }
}
</style>
