<template>
  <el-dialog :close-on-click-modal="false" class="add-course-shop" :visible.sync="dialogVisible">
    <div class="title">商品课程选择</div>
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>
    <div class="content">
      <table-list
        ref="courseShopTable"
        :loading="loading"
        :data="courseShopList"
        :search-form="searchForm(this)"
        :columns="columns(this)"
        :options="{ selection: true, height: 400, selectable: selectEvent }"
        @search="onSearch"
        @selectionChange="handleSelectionChange"
      ></table-list>
      <div class="btns">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
const searchForm = () => [
  {
    label: '课程商品ID',
    prop: 'productId',
  },
  {
    label: '课程名称',
    prop: 'courseName',
  },
  {
    label: '课程类型',
    prop: 'courseType',
    type: 'select',
    children: [
      {
        value: 0,
        label: '录播课',
      },
      {
        value: 1,
        label: '直播课',
      },
    ],
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'productId',
    label: '课程ID',
  },
  {
    prop: 'name',
    label: '课程名称',
  },
  {
    prop: 'courseType',
    label: '课程类型',
    formatter: row => ['录播', '直播'][row.courseType],
  },
  {
    prop: 'teacherName',
    label: '任课老师',
  },
  {
    prop: 'durationTotal',
    label: '课程时长(分)',
  },
]
import TableList from '@/components/TableList'
import { classCertCourseShop, classCertCourseShopAdd } from '@/api/certificate'
import to from 'await-to'
export default {
  name: 'AddCourseShop',
  components: { TableList },
  props: {
    classCertId: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      searchForm,
      columns,
      dialogVisible: false,
      loading: false,
      courseShopList: [],
      select: [],
      queryParams: {},
    }
  },
  methods: {
    show(select = []) {
      this.dialogVisible = true
      this.getList()
      this.$nextTick(() => {
        this.$refs.courseShopTable.$refs.tableList.clearSelection()
        if (select.length) {
          select.forEach(row => {
            this.$refs.courseShopTable.$refs.tableList.toggleRowSelection(row)
          })
        }
      })
    },
    async getList() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(
        classCertCourseShop({ ...this.queryParams, classCertId: this.classCertId }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.courseShopList = res.data
    },
    async submit() {
      const [, err] = await to(
        classCertCourseShopAdd({
          productIdList: this.select.map(item => item.productId),
          classCertId: this.classCertId,
        }),
      )
      if (err) return this.$message.warning(err.msg)
      this.$message.success('添加成功')
      this.$emit('success')
      this.dialogVisible = false
    },
    // 查询按钮
    onSearch(queryParams) {
      this.queryParams = queryParams
      this.getList()
    },
    // 多选
    handleSelectionChange(val) {
      this.select = val
    },
    selectEvent(row) {
      return !row.flag
    },
  },
}
</script>
<style lang="scss" scoped>
.add-course-shop {
  ::v-deep.el-dialog {
    position: relative;
    width: 1000px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 14px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .title {
      height: 62px;
      line-height: 62px;
      border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .content {
      padding: 30px 10px;
      > .btns {
        text-align: right;
        padding-top: 30px;
      }
    }
    .el-form-item {
      .el-input,
      .el-select,
      .el-date,
      .el-date-editor {
        width: 200px !important;
      }
    }
  }
}
</style>
