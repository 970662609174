<template>
  <div class="training-add-course">
    <table-list
      :loading="loading"
      :data="dataList"
      :columns="columns(this)"
      :btns="btns(this)"
      :options="{ selection: true }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
    ></table-list>
    <AddCourseShop ref="addCourseShop" :class-cert-id="classCertId" @success="addSuccess" />
    <AddCoursePackage ref="addCoursePackage" :class-cert-id="classCertId" @success="addSuccess" />
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '从课程商城选择',
    method: _this.handleAddFromShop,
    width: 120,
  },
  {
    label: '证书课程包中选择',
    method: _this.handleAddFromPacket,
    width: 140,
  },
  {
    label: '删除',
    type: 'danger',
    method: _this.handleDelete,
  },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'courseName',
    label: '课程名称',
  },
  {
    prop: 'teacherName',
    label: '讲师',
  },
  {
    prop: 'courseType',
    label: '课程类型',
    formatter: row => ['录播', '直播'][row.courseType],
  },
  {
    prop: 'courseTrainType',
    label: '课程培训类型',
    render: (h, { row }) => {
      return [
        h(
          'el-select',
          {
            props: {
              value: row.courseTrainType == 0 ? '' : row.courseTrainType,
              placeholder: '请选择类型',
            },
            on: {
              change: async v => {
                await _this.handleTrainTypeChange(v, row)
              },
            },
          },
          [
            h('el-option', {
              props: { value: 1, label: '拓展课' },
            }),
            h('el-option', {
              props: { value: 2, label: '考证课' },
            }),
          ],
        ),
      ]
    },
  },
  {
    prop: 'durationTotal',
    label: '总时长（分钟）',
  },
]
import TableList from '@/components/TableList'
import { classCertCourse, classCertCourseDelete, courseTrainTypeUpdate } from '@/api/certificate'
import AddCourseShop from './addCourseShop'
import AddCoursePackage from './addCoursePackage'
import to from 'await-to'
export default {
  name: 'TrainingAddCourse',
  components: {
    TableList,
    AddCourseShop,
    AddCoursePackage,
  },
  props: {
    classCertId: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      btns,
      columns,
      loading: false,
      dataList: [],
      selectList: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(
        classCertCourse({ ...this.queryParams, classCertId: this.classCertId }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.dataList = res.data
    },
    // 查询按钮
    onSearch(queryParams) {
      this.queryParams = queryParams
      this.getList()
    },
    handleAddFromShop() {
      this.$refs.addCourseShop.show()
    },
    handleAddFromPacket() {
      this.$refs.addCoursePackage.show()
    },
    addSuccess() {
      this.getList()
    },
    handleDelete() {
      if (!this.selectList.length) return this.$message.warning('请先选择要删除的课程')
      let ids = []
      this.selectList.map(item => ids.push(item.classCourseId))
      this.$confirm('确认删除课程吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(classCertCourseDelete({ classCourseIdList: ids }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getList()
      })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectList = val
    },
    async handleTrainTypeChange(v, row) {
      const [, err] = await to(
        courseTrainTypeUpdate({ classCourseId: row.classCourseId, courseTrainType: v }),
      )
      if (err) return this.$message.warning(err.msg)
      row.courseTrainType = v
    },
  },
}
</script>
