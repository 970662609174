<template>
  <div class="cert-traning-manage-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">
          编辑证书培训
          <el-button style="width:80px;height:25px;margin-left:10px" @click="back">返回</el-button>
        </span>
        <div class="btns">
          <el-button v-if="form.publishStatus == 1" @click="cancel">取消培训</el-button>
          <el-button v-if="form.publishStatus == 1" @click="publish">确认发布</el-button>
        </div>
      </div>
      <div class="card-content">
        <el-tabs v-model="activeTab" tab-position="left">
          <el-tab-pane label="基础信息" name="1">
            <div class="title">
              <span>基本信息</span>
              <el-button type="primary" @click="infoSave">保存</el-button>
            </div>
            <div class="tab-main">
              <el-form ref="form" :model="form" label-width="130px" :rules="rule">
                <el-form-item label="证书培训名称:" prop="className">
                  <el-input v-model="form.className" placeholder="请输入4~28个字证书培训名称">
                  </el-input>
                </el-form-item>
                <el-form-item label="适用证书:" prop="productId">
                  <el-select
                    v-model="form.productAttributeCategory"
                    placeholder="请选择证书类型"
                    @change="categoryChange"
                  >
                    <el-option
                      v-for="item in category"
                      :key="item.productAttributeCategoryId"
                      :value="item.productAttributeCategoryId"
                      :label="item.name"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-model="form.productId"
                    value-key="productId"
                    placeholder="请选择证书"
                    @change="certChange"
                  >
                    <el-option
                      v-for="item in listReview"
                      :key="item.productId"
                      :value="item.productId"
                      :label="item.name"
                      :disabled="item.publishStatus == 0"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="form.productAttributeCategory == 3"
                  label="证书等级:"
                  prop="grade"
                >
                  <el-radio-group v-model="form.grade">
                    <el-radio label="1">一级</el-radio>
                    <el-radio label="2">二级</el-radio>
                    <el-radio label="3">三级</el-radio>
                    <el-radio label="4">四级</el-radio>
                    <el-radio label="5">五级</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="考试时间:" prop="examTime">
                  <el-date-picker
                    v-model="form.examTime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="date"
                    placeholder="选择日期"
                    :picker-options="{
                      disabledDate: time => time.getTime() < Date.now() - 3600 * 1000 * 24,
                    }"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="教务:" prop="principal">
                  <!-- <el-input v-model="form.principal"> </el-input> -->
                  <ul v-if="form.principal && form.principal.length" class="principal">
                    <li v-for="(item, index) in form.principal" :key="item.studentId">
                      <span>{{ item.studentName }}</span>
                      <img
                        src="@/assets/common/close_icon.png"
                        class="close"
                        @click="removePrincipal(item, index)"
                      />
                    </li>
                  </ul>
                  <el-button @click="selectPrincipal">请选择</el-button>
                </el-form-item>
                <el-form-item ref="pic" label="封面图片" prop="pic">
                  <upload-image
                    v-model="form.pic"
                    :width-size="750"
                    :height-size="420"
                    :upload-img="$refs.pic"
                  ></upload-image>
                  <p class="tip">图片尺寸(750 * 420)，大小5MB内，支持扩展名：.png .jpg .jpeg</p>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="添加员工" name="2">
            <div class="title">
              <span>添加培训人员</span>
              <!-- <el-button type="primary" @click="infoSave">保存</el-button> -->
            </div>
            <div class="tab-main">
              <AddStudent v-if="activeTab == 2" :class-cert-id="classCertId" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="添加课程" name="3">
            <div class="title">
              <span>添加培训课程</span>
              <!-- <el-button type="primary" @click="infoSave">保存</el-button> -->
            </div>
            <div class="tab-main">
              <AddCourse v-if="activeTab == 3" :class-cert-id="classCertId" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="配置题库" name="4">
            <div class="title">
              <span>配置题库</span>
            </div>
            <div class="tab-main">
              <AddExam v-if="activeTab == 4" :class-cert-id="classCertId" />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>
    <add-principal-dialog
      ref="addPrincipalDialog"
      @handlePrincipalChange="handlePrincipalChange"
    ></add-principal-dialog>
  </div>
</template>
<script>
import UploadImage from '@/components/UploadImage'
import addPrincipalDialog from './components/AddPrincipalDialog'
import AddStudent from './components/AddStudent/index'
import AddCourse from './components/AddCourse/index'
import AddExam from './components/AddExam/index'
import to from 'await-to'
import { productCertCategory } from '@/api/center'
import {
  classCertBaseInfo,
  classCertBaseEdit,
  classCertPublish,
  classCertDelete,
  certAttrProduct,
} from '@/api/certificate'
export default {
  name: 'CertTrainingManageAdd',
  components: { UploadImage, addPrincipalDialog, AddStudent, AddCourse, AddExam },
  data() {
    return {
      activeTab: '1',
      form: {
        productId: '',
        certId: '',
        principal: [],
      },
      rule: {
        className: [
          { required: true, message: '请输入4~28个字证书培训名称', trigger: 'blur' },
          { min: 4, max: 28, message: '请输入4~28个字证书培训名称', trigger: 'blur' },
        ],
        productId: [{ required: true, message: '请选择证书', trigger: 'change' }],
        grade: [{ required: true, message: '请选择证书等级', trigger: 'change' }],
        examTime: [{ required: true, message: '请选择考试时间', trigger: 'change' }],
        principal: [{ required: true, message: '请选择教务', trigger: 'change' }],
        pic: [{ required: true, message: '请上传商品封面图', trigger: 'blur' }],
      },
      category: [],
      listReview: [],
      classCertId: '',
    }
  },
  mounted() {
    const { classCertId } = this.$route.params
    if (classCertId) {
      this.classCertId = classCertId
      this.getInfo()
    }
    this.getProductCertCategory()
  },
  methods: {
    // async create() {
    //   const [res, err] = await to(classCertCreate({}))
    //   if (err) return this.$message.warning(err.msg)
    //   this.classCertId = res.data
    // },、
    back() {
      this.$router.go(-1)
    },
    async getInfo() {
      const [res, err] = await to(classCertBaseInfo({ classCertId: this.classCertId }))
      if (err) return this.$message.warning(err.msg)
      this.form = res.data
      this.form.principal = JSON.parse(res.data.principal)
      this.form.productAttributeCategory = res.data.productAttributeCategory || ''
      if (res.data.productAttributeCategory) {
        await this.getListReviewByCategory(res.data.productAttributeCategory)
        if (this.listReview.filter(item => item.productId == res.data.productId).length) {
          this.form.productId = res.data.productId || ''
          this.form.certId = res.data.certId || ''
        } else {
          this.form.productId = ''
          this.form.certId = ''
        }
      } else {
        this.form.productId = res.data.productId || ''
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    async getProductCertCategory() {
      const [res, err] = await to(productCertCategory({}))
      if (err) return this.$message.warning(err.msg)
      this.category = res.data
    },
    async getListReviewByCategory(productAttributeCategoryId) {
      const [res, err] = await to(certAttrProduct({ productAttributeCategoryId }))
      if (err) return this.$message.warning(err.msg)
      this.listReview = res.data
    },
    categoryChange(e) {
      this.form.productId = ''
      this.form.certId = ''
      this.form.grade = ''
      this.getListReviewByCategory(e)
    },
    certChange(e) {
      this.form.productId = e
      this.form.certId = this.listReview.filter(item => item.productId == e)[0].certId
    },
    selectPrincipal() {
      this.$refs.addPrincipalDialog.show(this.form.principal || [], this.classCertId)
    },
    removePrincipal(item, index) {
      this.form.principal.splice(index, 1)
    },
    handlePrincipalChange(val) {
      this.form.principal = val
    },
    infoSave() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          const [, err] = await to(
            classCertBaseEdit({
              ...this.form,
              classCertId: this.classCertId,
              principal: JSON.stringify(this.form.principal),
            }),
          )
          if (err) return this.$message.warning(err.msg)
          this.$message.success('保存成功')
        }
      })
    },
    async publish() {
      const [, err] = await to(classCertPublish({ classCertId: this.classCertId }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('发布成功')
      this.$router.push('/certificate/trainingManage')
    },
    async cancel() {
      const [, err] = await to(classCertDelete({ classCertId: this.classCertId }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('取消成功')
      this.$router.push('/certificate/trainingManage')
    },
  },
}
</script>
<style lang="scss" scoped>
.cert-traning-manage-add {
  ::v-deep.el-tabs {
    .el-tabs__item {
      width: 180px;
      height: 60px;
      background: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 60px;
      color: #c9c9c9;
      text-align: center;
      border-right: 2px solid #f5f5f5;
      &.is-active {
        color: #ff7b33;
        background: #fcfbfa;
      }
    }
  }
  ::v-deep.el-card {
    .el-card__body {
      padding: 0;
    }
    .card-content {
      height: calc(100vh - 210px);
      .title {
        height: 78px;
        border-bottom: 1px solid #e2e2e2;
        font-size: 18px;
        font-weight: 500;
        line-height: 78px;
        color: #4d4e5d;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      #pane-1 {
        .el-form {
          .select-list {
            .select-item {
              width: 98px;
              height: 40px;
              line-height: 40px;
              background: #ff7b33;
              border-radius: 4px;
              text-align: center;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .el-select {
            width: 350px;
            margin-right: 24px;
          }
          .el-input {
            width: 350px;
          }
        }
        .el-form-item__content {
          width: 790px;
          .uploader {
            height: 120px;
            margin-bottom: 12px;
            .el-upload {
              box-sizing: border-box;
              height: 120px;
              width: 160px;
            }
          }
          .tip {
            font-size: 12px;
            line-height: 12px;
            color: #909399;
          }
        }
      }
    }
    .el-card__header {
      .clearfix {
        display: flex;
        align-items: center;
        .btns {
          margin-left: auto;
        }
      }
    }
  }
  ::v-deep.el-tabs {
    height: 100%;
    .el-tabs__header {
      height: 100%;
      padding: 20px;
      border-right: 1px solid #e2e2e2;
      margin-right: 0;
    }
    .el-tabs__nav-wrap::after {
      display: none;
    }
    .tab-main {
      height: calc(100vh - 290px);
      overflow: auto;
      padding: 20px;
      box-sizing: border-box;
    }
  }
  .principal {
    li {
      box-sizing: border-box;
      float: left;
      position: relative;
      cursor: pointer;
      min-width: 80px;
      padding: 0 13px;
      height: 40px;
      opacity: 1;
      background: #ffffff;
      border: 1px solid #d5d5d5;
      border-radius: 5px;
      text-align: center;
      font-size: 14px;
      color: #333333;
      margin-right: 25px;
      margin-bottom: 10px;
      .close {
        position: absolute;
        width: 14px;
        height: 14px;
        right: -5px;
        top: -5px;
      }
    }
  }
}
</style>
